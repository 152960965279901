

async function authChecker () {
  const token = localStorage.getItem('ALtoken');
  if (!token) return false;
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/isValid`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await res.json();
  if (data.isValid === true) return true;
  return false;

}


const AuthService = {
  isAuthenticated() {
    const token = localStorage.getItem('ALtoken');
    if (!token) return false;
    return true;
  },

  login(token: string) {
    localStorage.setItem('ALtoken', token);
  },

  logout() {
    localStorage.removeItem('ALtoken');
  }
};

export default AuthService;
export { authChecker };
