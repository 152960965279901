import axios from 'axios';

const axiosWithAuth = () => {
  const token = localStorage.getItem('ALtoken');
  return axios.create({
      headers: {
      Authorization: `Bearer ${token}`,
      },
  });
};

async function fetchProtectedData() {
  const res = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/userInfo`);
  return res.data;
}

async function getAccount() {
  const user = await fetchProtectedData();
  const account = {
    displayName: user.name,
    organization: user.organizationName,
    email: user.email,
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  };
  return account;
}

export default getAccount;