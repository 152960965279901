import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Stack,
  Card,
  CardContent,
  Container,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import { Helmet } from 'react-helmet-async';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';
import ContactCard from '../layouts/dashboard/ContactCard';



export default function AccountPage() {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [replyEmail, setReplyEmail] = useState<string>('');
    const [administrativeReplyEmail, setAdministrativeReplyEmail] = useState<string>('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    const showAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    useEffect(() => {
        // Get the reply email from the server
        axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/getAccountInfo`)
        .then((res) => {
            setReplyEmail(res.data.replyEmail);
            setAdministrativeReplyEmail(res.data.administrativeReplyEmail);
        })

    }, []);

    const handlePasswordChange = async () => {
    if (!currentPassword) {
        showAlert('Please enter your current password!', 'warning');
    } else if (!newPassword) {
        showAlert('Please enter a new password!', 'warning');
    } else if (!confirmPassword) {
        showAlert('Please confirm your new password!', 'warning');
    } else if (newPassword !== confirmPassword) {
        showAlert('Passwords do not match!');
    } else if (newPassword.length < 8) {
        showAlert('Password must be at least 8 characters long!', 'warning');
    } else {
        // Send the currentPassword, newPassword to server to handle password change.
        // For simplicity, we're assuming success here.
        axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/changePassword`, { currentPassword, newPassword })
        .then((res) => {
            if (res.data === 'Password changed successfully!') {
                showAlert(res.data, 'success');
            } else {
                showAlert(res.data, 'warning');
            }
            
        })

    }
    };

    const handleReplyEmailChange = async () => {
        // Send the reply email to the server to handle reply email change.
        // For simplicity, we're assuming success here.
        axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/changeReplyEmail`, { replyEmail })
        showAlert('Admissions Reply-To Email changed successfully!', 'success');
    }

    const handleAdministrativeReplyEmailChange = async () => {
        // Send the reply email to the server to handle reply email change.
        // For simplicity, we're assuming success here.
        axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/changeAdministrativeReplyEmail`, { administrativeReplyEmail })
        showAlert('Administrative Reply-To Email changed successfully!', 'success');
    }

  return (
    <>
        <Container maxWidth="md">
            <Helmet>
            <title>Account | Ask Lilac</title>
            </Helmet>
            <Typography variant="h4" gutterBottom>
                Account
            </Typography>
            <Snackbar 
                open={alertOpen} 
                autoHideDuration={6000} 
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setAlertOpen(false)} 
                    severity={alertSeverity}
                    variant="filled"
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
                <Card sx={{ width: '80%' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Admissions Email
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            This is the email address that will be used to receive reply requests from your prospective families.
                        </Typography>
                        <br />
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Admissions Email"
                                type="email"
                                value={replyEmail}
                                onChange={(e) => setReplyEmail(e.target.value)} />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '25%', alignSelf: 'center' }}
                                onClick={handleReplyEmailChange}
                            >Apply</Button>
                        </Stack>
                    </CardContent>
                </Card>
                <br />
                <Card sx={{ width: '80%' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Administrative Email
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            This is the email address that will be used to receive reply requests from your current students and families.
                        </Typography>
                        <br />
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Administrative Email"
                                type="email"
                                value={administrativeReplyEmail}
                                onChange={(e) => setAdministrativeReplyEmail(e.target.value)} />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '25%', alignSelf: 'center' }}
                                onClick={handleAdministrativeReplyEmailChange}
                            >Apply</Button>
                        </Stack>
                    </CardContent>
                </Card>
                <br />
                <Card sx={{ width: '80%' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Change Password
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            To change your password, please enter your current password and a new password.
                        </Typography>
                        <br />
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Current Password"
                                type="password"
                                required
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)} />
                            <TextField
                                fullWidth
                                label="New Password"
                                type="password"
                                required
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)} />
                            <TextField
                                fullWidth
                                label="Confirm New Password"
                                type="password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)} />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '25%', alignSelf: 'center' }}
                                onClick={handlePasswordChange}
                            >
                                Apply
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>


                <br />
                <Card sx={{ width: '80%' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom align="center">
                            Delete Account
                        </Typography>
                        <Typography variant="body1" gutterBottom align="center">
                            To delete your account, please contact us at{' '}
                            <a href="mailto:support@asklilac.com">
                                support@asklilac.com
                            </a>
                        </Typography>
                    </CardContent>
                </Card>
                <div style={{ padding: '20px', width: '85%'}}>
                    <ContactCard />
                </div>
            </Box>
        </Container>
    </>
  );
};
