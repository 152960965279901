import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography } from '@mui/material';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';
// components
import WebsiteCard from '../sections/@dashboard/knowledgeBase/WebsiteCard';
import ComingSoonCard from '../sections/@dashboard/knowledgeBase/ComingSoon';
import ContactCard from '../layouts/dashboard/ContactCard';
import GoogleDriveFileList from '../sections/@dashboard/knowledgeBase/GoogleDrive';


// ----------------------------------------------------------------------

export default function KnowledgePage() {
  const [integrations, setIntegrations] = useState([]);
  const [isGDrive, setIsGDrive] = useState(false);

  useEffect(() => {
    axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations`)
      .then(res => {
        // Assuming res.data.integrations is the array where you want to check the type
        if (res.data.integrations && res.data.integrations.some((integration: { type: string; }) => integration.type === 'googleDrive')) {
            setIsGDrive(true);
        } else {
            setIsGDrive(false);
        }
      })
      .catch(err => {
          console.error(err);
          setIsGDrive(false);
      });
  });


  

  

  return (
    <>
      <Helmet>
        <title> Knowlege Base | Ask Lilac </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Knowledge Base
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mb: 5 }}>
          <WebsiteCard />
        </Stack>

        {isGDrive && (
          <Stack direction="row" spacing={1} sx={{ mb: 5 }}>
            <GoogleDriveFileList />
          </Stack>
        )}
        
        <Stack direction="row" spacing={1} sx={{ mb: 5 }}>
          <ComingSoonCard />
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mb: 5 }}>
          <ContactCard />
        </Stack>

      </Container>
    </>
  );
}