import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthService, { authChecker } from './authService';

// @ts-ignore
import DashboardLayout from './layouts/dashboard';
// @ts-ignore
import SimpleLayout from './layouts/simple';

import IntegrationsPage from './pages/IntegrationsPage';
import ConversationsPage from './pages/ConversationsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import KnowledgePage from './pages/KnowledgePage';
import DashboardAppPage from './pages/DashboardAppPage';
import SignUpPage from './pages/SignUpPage';
import DesignerPage from './pages/DesignerPage';
import BillingPage from './pages/BillingPage';
import AccountPage from './pages/AccountPage';
import AllView from './sections/@dashboard/conversations/AllView';
import ProspectsView from './sections/@dashboard/conversations/ProspectsView';
import CurrentView from './sections/@dashboard/conversations/CurrentView';
import ReplyRequestsView from './sections/@dashboard/conversations/ReplyRequestsView';
import ReportsPage from './pages/ReportsPage';


export default function Router() {
  const [authState, setAuthState] = React.useState({
    isLoading: true,
    isAuthenticated: false
  });
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

  useEffect(() => {
    async function checkAuth() {
      const isAuthenticated = await authChecker();
      setAuthState({
        isLoading: false,
        isAuthenticated
      });
    }
    checkAuth();
  }, []);

  const baseRoutes = [
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const authRoutes = [
    {
      path: '/',
      element: authState.isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'conversations', element: <ConversationsPage />, children: [
          { index: true, path: 'all', element: <AllView /> },
          { path: 'prospects', element: <ProspectsView /> },
          { path: 'current', element: <CurrentView /> },
          { path: 'reply-requests', element: <ReplyRequestsView /> },

        ] },
        { path: 'knowledge-base', element: <KnowledgePage /> },
        { path: 'designer', element: <DesignerPage /> },
        { path: 'reports', element: <ReportsPage /> },
        { path: 'integrations', element: <IntegrationsPage /> },
        { path: 'billing', element: <BillingPage /> },
        { path: 'account', element: <AccountPage /> },
      ],
    },
    {
      path: 'login',
      element: !authState.isAuthenticated ? <LoginPage /> : <Navigate to="/" />,
    },
    {
      path: 'signup',
      element: !authState.isAuthenticated ? <SignUpPage /> : <Navigate to="/" />,
    }
  ];

  const routesConfig = authState.isLoading ? baseRoutes : [...authRoutes, ...baseRoutes];
  const routes = useRoutes(routesConfig);

  // Return this page when Loading and checking auth
  if (authState.isLoading) {
    return <div>|</div>;
  }

  return (
    <Elements stripe={stripePromise}>
        {routes}
    </Elements>
  );
}