import React from 'react';
import { Grid, Typography, Card, CardContent, Button } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';

type PlansProps = {
  selectedPlan: string;
  setSelectedPlan: (plan: string) => void;
};

export default function Plans({ selectedPlan, setSelectedPlan }: PlansProps) {
  return (
    <Grid container spacing={4}>
      {/* Starter Plan */}
      <Grid item xs={12} sm={6}>
        <Card 
            variant="outlined" 
            style={{
                borderColor: selectedPlan === 'starter' ? 'purple' : 'rgba(0, 0, 0, 0.23)', // Change the color 'blue' to whatever you prefer
                height: '100%',
                borderWidth: selectedPlan === 'starter' ? '2px' : '1px' // Make the border thicker when selected
            }}
            onClick={() => setSelectedPlan('starter')}
        >
          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                    <div style={{ height: '1.3rem' }}/>
                    <Typography variant="h5">Starter</Typography>
                    <Typography variant="body2">Includes 100 free conversations</Typography>
                    <Typography variant="body2">Up to 100 webpages</Typography>
                    <Typography variant="body2">Email Support</Typography>
                    <Typography variant="body2">Monthly Automatic Website Data Update</Typography>
                    <Typography variant="body2">Multilingual Support</Typography>
                    <Typography variant="body2">Customizable Chatbot</Typography>
                </div>
                <div>
                    <Typography variant="h5">$249.95/month</Typography>
                    <Typography variant="body2">+ $69 per 100 conversations</Typography>
                </div>
          </CardContent>
        </Card>
      </Grid>

      {/* Standard Plan */}
      <Grid item xs={12} sm={6}>
        <Card 
            variant="outlined" 
            style={{
                borderColor: selectedPlan === 'standard' ? 'purple' : 'rgba(0, 0, 0, 0.23)',
                height: '100%',
                borderWidth: selectedPlan === 'standard' ? '2px' : '1px' // Make the border thicker when selected
            }}
            onClick={() => setSelectedPlan('standard')}
        >
          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                    <Typography variant="overline" style={{ color: 'purple' }}>Most Popular</Typography>
                    <Typography variant="h5">Standard</Typography>
                    <Typography variant="body2">Includes 300 free conversations</Typography>
                    <Typography variant="body2">Up to 250 webpages</Typography>
                    <Typography variant="body2">Personalized Support</Typography>
                    <Typography variant="body2">Weekly Automatic Website Data Update</Typography>
                    <Typography variant="body2">Multilingual Support</Typography>
                    <Typography variant="body2">Customizable Chatbot</Typography>
                    <Typography variant="body2">Additional Knowledge Sources (coming soon)</Typography>
                    <Typography variant="body2">CRM Integrations (coming soon)</Typography>
                </div>

                <br />
                <div>
                    <Typography variant="h5">$499.95/month</Typography>
                    <Typography variant="body2">+ $62 per 100 conversations</Typography>
                </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
