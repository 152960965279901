import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, children } = item;
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const isRouteActive = location.pathname.startsWith(path);
  const displayChildren = children && (open || isRouteActive);

  useEffect(() => {
    // Automatically collapse the submenu if the current path does not start with this item's path
    const isCurrentPath = location.pathname.startsWith("/conversations");
    setOpen(isCurrentPath);
  }, [location, path]);

  const handleClick = () => {
    if (children) {
      setOpen(!open);
    }
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        onClick={handleClick}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {children && (displayChildren ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </StyledNavItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((childItem) => (
              <NavItem key={childItem.title} item={childItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
