import React, { useRef, useEffect, ChangeEvent, useState } from 'react';
import { Container, Typography, Box, IconButton, Button, Stack, Card, TextField, CircularProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import PaletteIcon from '@mui/icons-material/Palette';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import axiosWithAuth from '../../auth/login/axiosWithAuth';
import ContactCard from '../../../layouts/dashboard/ContactCard';
import InstructionsCard from '../../../layouts/InstructionsCard';


interface ChatbotDesignerProps {
    message: any,
}


const ChatbotDesigner: React.FC<ChatbotDesignerProps> = ({ message }) => {
    const [cbscriptContent, setCbscriptContent] = React.useState('');
    const [cbframeContent, setCbframeContent] = React.useState<string>('');
    const [headerText, setHeaderText] = React.useState('');
    const [firstMessage, setFirstMessage] = React.useState('');
    const [botName, setBotName] = React.useState('');
    const [orgID, setOrgID] = React.useState('');
    const [pColor, setPColor] = React.useState('#a52be6');
    const [sColor, setSColor] = React.useState('#ce1cc5');
    const [tooltipTitle, setTooltipTitle] = React.useState('Copy to Clipboard');
    const [isGenerateLoading, setIsGenerateLoading] = React.useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    const showAlert = (alMessage: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertMessage(alMessage);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    useEffect(() => {

        if (message.headerText) {
            setHeaderText(message.headerText);
        }

        if (message.agentFirstMessage) {
            setFirstMessage(message.agentFirstMessage);
        }
        if (message.agentName) {
            setBotName(message.agentName);
        }
        if (message.primaryColor) {
            setPColor(message.primaryColor);
        }
        if (message.secondaryColor) {
            // set the secondary color in color picker
            setSColor(message.secondaryColor);
        }

        const fetchData = async () => {
            try {
                // Get org id from backend
                const res = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/orgID`);
                
                if (!res.data.orgID) {
                    console.error("orgID not found in Axios response!");
                    return;
                }
    
                const currentOrgID = res.data.orgID;
                setOrgID(currentOrgID);

                
                // Fetch the original cbscript.js content
                const scriptResponse = await fetch(`https://cdn.asklilac.com/${currentOrgID}/cbscript.js`);
                const scriptData = await scriptResponse.text();
                setCbscriptContent(scriptData);
    
                // Fetch the original cbframe content
                const frameResponse = await fetch(`https://cdn.asklilac.com/${currentOrgID}/cbframe.html`);
                const frameData = await frameResponse.text();
                setCbframeContent(frameData);
                loadChatbotScript(currentOrgID);
    
            } catch (error) {
                console.error('Error:', error);
            }
        };
    
        fetchData();

        // turn org id into a number
        const newOrgID = Number(orgID);

        return () => {
            // Remove the script
            const existingScript = document.querySelector(`script[src="https://cdn.asklilac.com/${newOrgID}/cbscript.js"]`);
            if (existingScript) {
                existingScript.remove();
            }
            
            // Remove the iframe
            const iframeElement = document.getElementById('chatbot-iframe');
            if (iframeElement) {
                iframeElement.remove();
            }

            // Remove the launcher
            const launcherElement = document.getElementById('chatbot-launcher'); // or use the appropriate identifier
            if (launcherElement) {
                launcherElement.remove();
            }
            };
    }, []);
    
    
    
    


    const loadChatbotScript = (currentOrgID: number) => {
        const script = document.createElement('script');
        script.id = 'chatbot-script';
        script.src = `https://cdn.asklilac.com/${currentOrgID}/cbscript.js`;
        script.async = true;
        document.body.appendChild(script);
        return script;
    };



    const updateTextInCbframe = (newHeaderText: string, newFirstMessage: string, newBotName: string) => {
        const iframeElement = document.getElementById('chatbot-iframe') as HTMLIFrameElement;
        if (!iframeElement) {
            console.error("iFrame element not found!");
            return;
        }
    
        if (iframeElement.contentDocument) {
            const iframeDoc = iframeElement.contentDocument;
    
            const headerElem = iframeDoc.getElementById('headerText');
            const firstMsgElem = iframeDoc.getElementById('firstMessage');
            const botNameElem = iframeDoc.getElementById('botName');
    
            if (headerElem && newHeaderText !== '') headerElem.textContent = newHeaderText;
            if (firstMsgElem && newFirstMessage !== '') firstMsgElem.textContent = newFirstMessage;
            if (botNameElem && newBotName !== '') botNameElem.textContent = newBotName;
        } else {
            console.error("iFrame contentDocument not accessible!");
        }
    };
    


    const applyChanges = () => {
        updateTextInCbframe(headerText, firstMessage, botName);
        };

        const handlePColorChange = (event: ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setPColor(color);
        const launcher = document.getElementById("chatbot-launcher");
        if (launcher) {
            launcher.style.backgroundColor = color;
        }

        const iframeElement = document.getElementById('chatbot-iframe') as HTMLIFrameElement;

        if (iframeElement && iframeElement.contentDocument) {
            const iframeDoc = iframeElement.contentDocument;
            iframeDoc.documentElement.style.setProperty('--primary-color', color);
        }
    };

    const handleSColorChange = (event: ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setSColor(color);

        const iframeElement = document.getElementById('chatbot-iframe') as HTMLIFrameElement;

        if (iframeElement && iframeElement.contentDocument) {
            const iframeDoc = iframeElement.contentDocument;
            iframeDoc.documentElement.style.setProperty('--secondary-color', color);
        }
    };


    const fetchUpdatedContents = async () => {
        try {
            const scriptResponse = await fetch(`https://cdn.asklilac.com/${orgID}/cbscript.js`);
            const scriptData = await scriptResponse.text();
            setCbscriptContent(scriptData);
    
            const frameResponse = await fetch(`https://cdn.asklilac.com/${orgID}/cbframe.html`);
            const frameData = await frameResponse.text();
            setCbframeContent(frameData);
    
            // Now, reload the iframe
            const iframe = document.getElementById('chatbot-iframe') as HTMLIFrameElement;
            if (iframe) {
                iframe.src = `${iframe.src.split('?')[0]}?timestamp=${new Date().getTime()}`;
            }
        } catch (error) {
            console.error('Error fetching updated contents:', error);
        }
    };
    

    const handleGenerateScript = async () => {
        setIsGenerateLoading(true);
        const bgColor = (document.getElementById("chatbot-launcher") as HTMLElement).style.backgroundColor || '#04a84e';
        const iconColor = (document.querySelector("#chatbot-launcher i") as HTMLElement).style.color || 'white';
        // Prepare the data to send to the backend
        const payload = {
            primaryColor: pColor,
            secondaryColor: sColor,
            headerText,
            firstMessage,
            botName
        };
    
        try {
            const response = await axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/updateChatbotFiles`, payload);

            
            // Handle the response accordingly
            if (response) {
                // Reload the iframe
                await fetchUpdatedContents();
                showAlert('Agent updated successfully!', 'success');
            } else {
                console.error('Failed to update the content:', response);
            }
    
        } catch (error) {
            console.error('Error sending data to the backend:', error);
        } finally {
            setIsGenerateLoading(false);
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`<script src="https://cdn.asklilac.com/${orgID}/cbscript.js"></script>`).then(() => {
            setTooltipTitle('Copied!');
            setTimeout(() => setTooltipTitle('Copy to Clipboard'), 2000);
        }).catch((error) => {
            console.error("Copy to clipboard failed", error);
        });
    };
    
    

  return (
    <>
        <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => setAlertOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setAlertOpen(false)}
                severity={alertSeverity}
                variant="filled"
            >
                {alertMessage}
            </Alert>
        </Snackbar>
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'fit-content',
                minHeight: '80vh',
                paddingBottom: '2rem'
            }}
        >
            <Box
                sx={{
                    mb: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <PaletteIcon 
                    sx={{ fontSize: { xs: '4rem', sm: '6rem', md: '7rem' }, mb: 2 }} 
                    color="primary" 
                />
                <Typography variant="h3" align="center" gutterBottom>
                    Customize your Support Agent
                </Typography>
                
            </Box>

                <Card variant="elevation" sx={{ mt: 1, padding: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', background: "#a52be6", color: "white" }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Support Agent Preview
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        The support agent is live in the bottom right and receives your messages. Feel free to click on it and try it out. Test your changes in real-time!
                    </Typography>
                </Card>
              <Stack direction="row" spacing={5} sx={{mt:5}} alignItems="center" justifyContent="center">
                  <Card variant="outlined" sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <input type="color" value={pColor} onChange={handlePColorChange} />
                      <Typography variant="h6" align="center" gutterBottom mt={2}>
                          Primary Color
                      </Typography>
                  </Card>

                  <Card variant="outlined" sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <input type="color" value={sColor} onChange={handleSColorChange} />
                      <Typography variant="h6" align="center" gutterBottom mt={2}>
                          Secondary Color
                      </Typography>
                  </Card>
              </Stack>
              <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Support Agent Name"
                  value={botName}
                  onChange={(e) => setBotName(e.target.value)} />
              <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Support Chat Name (displayed at the top)"
                  value={headerText}
                  onChange={(e) => setHeaderText(e.target.value)} />
              <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="First Message from Agent"
                  value={firstMessage}
                  onChange={(e) => setFirstMessage(e.target.value)} />



              <Box sx={{ mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={handleGenerateScript} disabled={isGenerateLoading}>
                      {isGenerateLoading ? <CircularProgress size={24} color="inherit" /> : "Apply Changes"}
                  </Button>
              </Box>

               
              
              <Card sx={{ mt: 8, padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                  <TextField
                      fullWidth
                      label="Copy this code onto your website"
                      variant="outlined"
                      margin="normal"
                      value={`<script src="https://cdn.asklilac.com/${orgID}/cbscript.js"></script>`}
                      InputProps={{
                          readOnly: true,
                      }}
                      sx={{ flex: 1, margin: '0 !important' }} // Override the margin and ensure the text field takes up the full width
                  />
                  <Tooltip title={tooltipTitle} placement="top">
                      <IconButton onClick={handleCopyToClipboard} color="primary" sx={{ marginLeft: 2 }}>
                          <FileCopyIcon />
                      </IconButton>
                  </Tooltip>
              </Card>
              
                <div style={{ padding: '30px', width: '107%'}}>
                    <InstructionsCard />
                </div>
                <div style={{ width: '100%' }}>
                    <ContactCard />
                </div>


          </Container>
          </>
  );
};

export default ChatbotDesigner;

