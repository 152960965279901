import React, { useEffect, useState, useRef } from 'react';
import parse from 'html-react-parser';
import { Button, Box, Typography, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import axiosWithAuth from '../../auth/login/axiosWithAuth';

type ProspectType = {
    id: string;
    name: string;
    email: string;
    location?: string;
    status: string;
};

type Message = {
    bot: boolean;
    content: string;
    createdAt: string;
};

type ProspectDetailProps = {
    prospect: ProspectType;
    onBack: () => void;
};

const ChatHistory = styled(Box)({
    height: '550px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)'
});

const ChatMessage = styled(Typography)({
    padding: '10px',
    margin: '5px 0',
    borderRadius: '5px',
    maxWidth: '70%',
    wordBreak: 'break-word',
    backgroundColor: '#b3d9ff',
    alignSelf: 'flex-end'
});

const BotMessage = styled(ChatMessage)({
    backgroundColor: '#e6e6e6',
    alignSelf: 'flex-start'
});

const MessageContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
});

const ChatCard = styled(Card)({
    maxWidth: '600px',
    margin: '20px auto',
    height: '635px',
    overflow: 'auto',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)'
});

const ProspectDetail: React.FC<ProspectDetailProps> = ({ prospect, onBack }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);
    const chatRef = useRef<HTMLDivElement>(null);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/getMessages`, prospect)
            .then(res => {
                setMessages(res.data.messages.reverse());
                setDisplayedMessages(res.data.messages.reverse());
                setInitialLoad(true);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (chatRef.current && initialLoad) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
            setInitialLoad(false);
        }
    }, [displayedMessages]);

    const handleScroll = () => {
        if (chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current;
            if (scrollTop === 0 && displayedMessages.length < messages.length) {
                const endIdx = messages.length - displayedMessages.length;
                const startIdx = Math.max(endIdx - 8, 0);
                const nextBatch = messages.slice(startIdx, endIdx).reverse();
                setDisplayedMessages(prevMessages => [...prevMessages, ...nextBatch]);
                requestAnimationFrame(() => {
                    if (chatRef.current) {
                        const addedHeight = scrollHeight - chatRef.current.scrollHeight;
                        chatRef.current.scrollTop = scrollTop - addedHeight;
                    }
                });
            }
        }
    };

    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const day = date.getDate();
        const s = ["th", "st", "nd", "rd"];
        const v = day % 100;
        const ordinal = s[(v - 20) % 10] || s[v] || s[0];
        return `${date.toLocaleDateString('en-US', { weekday: 'long' })}, ${date.toLocaleDateString('en-US', { month: 'long' })} ${day}${ordinal}`;
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={onBack}>
                Back to List
            </Button>
            <ChatCard>
                <Box p={2}>
                    <Typography variant="h5">
                        {`Messages with: ${prospect.name}`}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {prospect.email}
                    </Typography>
                </Box>
                <ChatHistory ref={chatRef} onScroll={handleScroll}>
                    {displayedMessages.map((message, index) => {
                        const previousMessage = displayedMessages[index - 1];
                        const showDivider = index === 0 || (new Date(message.createdAt).toDateString() !== new Date(previousMessage?.createdAt).toDateString());

                        // Determine the replacement message based on prospect.status
                        const replacementMessage = prospect.status === "current" ? "I am unable to answer this question for you. Would you like to request an email reply for more help?" : prospect.status === "prospective" ? "That's a great question and might need more detailed information. Would you like to request an email reply from our admissions team?" : prospect.status === "theflash" ? "I am unable to answer this question for you. Would you like to request an email reply for more help?" : prospect.status === "null" ? "That's an interesting question! Would you like to request an email reply for more information?" : message.content;
                        // Replace message content with the replacement message if it is "NaN"
                        const messageContent = message.content === "NaN" ? replacementMessage : message.content;

                        return (
                            <React.Fragment key={index}>
                                {showDivider && (
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{ margin: '15px 0' }}>
                                        <Typography variant="body2" color="textSecondary">
                                            {formatDate(message.createdAt)}
                                        </Typography>
                                        <Divider sx={{ width: '100%', margin: '5px 0' }} />
                                    </Box>
                                )}
                                <MessageContainer sx={{ alignItems: message.bot ? 'flex-start' : 'flex-end' }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {new Date(message.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                                    </Typography>
                                    <Box display="flex" flexDirection="column" width="100%">
                                        {message.bot ? (
                                            <BotMessage variant="body2">
                                                {parse(messageContent)}
                                            </BotMessage>
                                        ) : (
                                            <ChatMessage variant="body2">
                                                {parse(messageContent)}
                                            </ChatMessage>
                                        )}
                                    </Box>
                                </MessageContainer>
                            </React.Fragment>
                        );
                    })}

                </ChatHistory>
            </ChatCard>
        </div>
    );
}

export default ProspectDetail;
