import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  CircularProgress, Grid, Container, Typography, Button, Box, Card, CardContent, Stack, Step, StepLabel, Stepper, Paper
} from '@mui/material';
import { AssignmentTurnedIn as KnowledgeBaseIcon, Code as DesignerIcon } from '@mui/icons-material';
// components
// sections
import {
  ConversationsCard,
  AppWidgetSummary,
  AppCurrentVisits,
  // AppCurrentSubject,
  AppConversionRates,
}
// @ts-ignore
from '../sections/@dashboard/app';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';



type Message = {
  id: string | number;
  bot: boolean;
  Prospect: {
    name: string;
  }
  content: string;
  createdAt: Date;
};
type AnsweredData = {
  label: string;
  value: number;
};
interface ChartDataItem {
  label: string;
  value: number;
}


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [displayedMessagesCount, setDisplayedMessagesCount] = React.useState(5);
  const [monthlyConversations, setMonthlyConversations] = React.useState(0);
  const [prospects, setProspects] = React.useState(0);
  const [questionsAsked, setQuestionsAsked] = React.useState(0);
  const [responseRequests, setResponseRequests] = React.useState(0);
  const [viewAllClickCount, setViewAllClickCount] = React.useState(0);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isDesigner, setIsDesigner] = React.useState(false);
  const [isScraped, setIsScraped] = React.useState(false);
  const [plan, setPlan] = React.useState('');
  const [answeredData, setAnsweredData] = React.useState<AnsweredData[]>([]);
  const [termsChartData, setTermsChartData] = React.useState<ChartDataItem[]>([]);
  const handleViewAllClick = handleViewAll;


  
  


  useEffect(() => {
    axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/isInitialized`)
      .then(res => {
        setIsScraped(res.data.isWebScraped);
        setIsDesigner(res.data.isDesignerInitialized);
        if (!res.data.isDesignerInitialized) {
          setIsLoaded(true);
          return null; 
        } 
        return axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/getDashboardData`);
      })
      .then(res => {
        if (res) { // Only proceed if the result exists
          setMessages(res.data.falseMessages);
          setMonthlyConversations(res.data.result.conversationCount);
          setProspects(res.data.result.trueProspectCount);
          setQuestionsAsked(res.data.result.messageCount);
          setResponseRequests(res.data.result.replyRequestCount);
          setAnsweredData([{ label: 'Answered', value: res.data.result.nonBotMessages.length }, { label: 'Unanswered', value: res.data.result.countBotNaNMessages }]);
          setTermsChartData(res.data.result.keywordsData);
          setIsLoaded(true);
          setPlan(res.data.result.plan);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  


  function handleViewAll() {
    if (displayedMessagesCount < messages.length) {
      setDisplayedMessagesCount(prevCount => Math.min(prevCount + 5, messages.length));
    }
  }
  
  
  function createSteps() {
    const steps = [];

    if (!isScraped) {
      steps.push(
        <Step key="knowledge-base">
          <StepLabel icon={<KnowledgeBaseIcon />}>
            <Typography variant="h6">
              1. Populate the Knowledge Base
            </Typography>
            <Typography variant="body1" paragraph>
              Begin by adding data to our knowledge base. This ensures your support agent learns and provides accurate responses.
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button component={Link} to="/knowledge-base" variant="contained" color="primary">
                Go to Knowledge Base
              </Button>
            </Box>
          </StepLabel>
        </Step>
      );
    }

    if (!isDesigner) {
      steps.push(
        <Step key="designer">
          <StepLabel icon={<DesignerIcon />}>
            <Typography variant="h6">
              {isScraped ? '1.' : '2.'} Design and Deploy Your Support Agent
            </Typography>
            <Typography variant="body1" paragraph>
              Once data is added, head to the designer section to customize and retrieve the code for your website.
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button component={Link} to="/designer" variant="contained" color="secondary">
                Head to Designer
              </Button>
            </Box>
          </StepLabel>
        </Step>
      );
    }

    return steps;
  }

  if (!isLoaded) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
      </Container>
    );

  }





  return (
    <>
      <Helmet>
        <title> Dashboard | Ask Lilac </title>
      </Helmet>
  
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hello there! Let's dive in.
        </Typography>
        {plan === 'free' && (
          <Card sx={{ mb: 5, width: 1, background: "#ffb74d" }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                You are currently on the Free Plan
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                The free plan is limited to 5 conversations. To upgrade, please visit the <Link to="/billing">Billing</Link> page.
              </Typography>
            </CardContent>
          </Card>
        )}
        {isDesigner ? (
          <Grid container >

            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Monthly Conversations" total={monthlyConversations} icon={'ant-design:wechat-filled'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Prospects" total={prospects} color="info" icon={'icon-park-outline:family'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Questions Asked" total={questionsAsked} color="warning" icon={'ant-design:wechat-filled'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Response Requests" total={responseRequests} color="error" icon={'bx:error'} />
              </Grid>



              <Grid item xs={6} md={6} lg={6}>
                <Card>
                  <AppCurrentVisits
                    title="Answered Questions"
                    subheader="Should your unanswered questions ratio exceed 25%, consider adding more data to your knowledge base."
                    chartData={answeredData}
                  />
                </Card>
              </Grid>

              <Grid item xs={6} md={6} lg={6}>
                <AppConversionRates
                  title="Keywords"
                  subheader="Terms that can be used to improve your website's search engine optimization (SEO)."
                  chartData={termsChartData}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>



                <Typography variant="body2" gutterBottom>
                  * Answered Questions and Keywords are based on messages from the current month.
                </Typography>

              </Grid>
              
            {/*
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <AppCurrentSubject
                    title="Most Common Question Topics"
                    chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                    chartData={[
                      { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                      { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                      { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                    ]}
                    // Add this if you have colors defined
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.info.main,
                      theme.palette.warning.main,
                    ]}
                  />
                </Card>
              </Grid>
            */}
              

              <Grid item xs={12} md={12} lg={12}>
                <ConversationsCard
                  title="Recent Questions We Couldn't Answer"
                  list={messages.slice(0, displayedMessagesCount).map(message => ({
                    id: message.id || faker.datatype.uuid(),
                    title: message.Prospect.name,
                    description: message.content,
                    image: `/assets/images/covers/cover_${faker.datatype.number({min: 1, max: 5})}.jpg`,
                    postedAt: new Date(message.createdAt)
                  }))}
                  onViewAll={handleViewAllClick}
                  showViewAllButton={displayedMessagesCount < messages.length}
                  isEmpty={messages.length === 0}
                />
              </Grid>



            </Grid>
          </Grid>
        ) : (
          <Container maxWidth="md">
            <Paper elevation={3}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ minHeight: '10vh', padding: 4 }}
              >
                <Typography variant="h3" gutterBottom align="center">
                  Welcome to Ask Lilac! 🎉
                </Typography>

                <Typography variant="h6" gutterBottom align="center">
                  Follow these steps to set up your support agent
                </Typography>

                <Stepper orientation="vertical">
                  {createSteps()}
                </Stepper>

              </Stack>
            </Paper>
          </Container>
        )}

      </Container>
    </>
  );
  
}
