import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, Card, CardContent, Grid } from '@mui/material';
import GoogleDriveIntegrationCard from '../sections/@dashboard/integrations/GoogleDriveAuth';

// Define consistent image styling
const imageStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'contain'
};

// Adjust the icon components to use the style
const VeracrossIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/veracross.png`} alt='Veracross' sx={imageStyle} />;
const OneDriveIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/onedrive.png`} alt='OneDrive' sx={imageStyle} />;
const DropboxIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/dropbox.svg`} alt='Dropbox' sx={imageStyle} />;
const BoxIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/box.png`} alt='Box' sx={imageStyle} />;
const HubspotIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/hubspot.png`} alt='Hubspot' sx={imageStyle} />;
const RavennaIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/ravenna.png`} alt='Ravenna' sx={imageStyle} />;
const BlackbaudIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/blackbaud.png`} alt='Blackbaud' sx={imageStyle} />;
const SalesforceIcon = () => <Box component="img" src={`${process.env.PUBLIC_URL}/assets/integrations/salesforce.png`} alt='Salesforce' sx={imageStyle} />;


const handleFolderCreated = (folderId: string) => {
  console.log("Folder created with ID:", folderId);
  // You can perform other actions here, like setting state or calling APIs
};

export default function IntegrationsPage() {
  return (
    <>
      <Helmet>
        <title>Integrations | Ask Lilac</title>
      </Helmet>

      <Container maxWidth="md">
        <Box sx={{ mb: 5 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Integrations
          </Typography>
        </Box>
        <GoogleDriveIntegrationCard />

        <br />
        <br />
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Coming Soon
          </Typography>
          <Typography variant="subtitle1" align="center" mb={3}>
            We're currently working on integrating the following services
          </Typography>
          <Grid container spacing={4}>
            {[
              { icon: <VeracrossIcon />, name: 'Veracross' },
              { icon: <OneDriveIcon />, name: 'OneDrive' },
              { icon: <DropboxIcon />, name: 'Dropbox' },
              { icon: <BoxIcon />, name: 'Box' },
              { icon: <HubspotIcon />, name: 'Hubspot' },
              { icon: <RavennaIcon />, name: 'Ravenna' },
              { icon: <BlackbaudIcon />, name: 'Blackbaud' },
              { icon: <SalesforceIcon />, name: 'Salesforce' },
            ].map((service) => (
              <Grid item xs={6} sm={3} md={3} key={service.name}>
                <Card sx={{ height: '200px', color: 'gray', textAlign: 'center', padding: 2, backgroundColor: '#f5f5f5' }}>
                  <CardContent>
                    <Box sx={{ width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {service.icon}
                    </Box>
                    <Typography variant="body1" mt={1}>
                      {service.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
