import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// @ts-ignore
import Iconify from '../../../components/iconify';

const SignUpForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [industries, setIndustries] = useState([
    'Agriculture',
    'Arts',
    'Education',
    'Finance',
    'Health',
    'Hospitality',
    'Manufacturing',
    'Media',
    'Real Estate',
    'Recreation',
    'Religion',
    'Restaurants',
    'Retail',
    'Services',
    'Technology',
    'Transportation',
    'Utilities',
    'Other'
  ]);
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    password: '',
    confirmPassword: '',
    industry: ''
  });

  const handleOpenDialog = (message: string) => {
    setDialogMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
      setOpenDialog(false);
  };

  const capitalizeFirstLetterOfEachWord = (string: string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }
  

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'name' || name === 'organization') {
      newValue = capitalizeFirstLetterOfEachWord(value);
    }

    if (name === 'password') {
      validatePassword(value);
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }

  const handleEmailChange = (e: any) => {
    handleChange(e); // Call the generic change handler
      
    if (isValidEmail(e.target.value) || e.target.value === '') {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address');
    }
  };
  
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      setPasswordError('Password should be at least 8 characters');
      return false;
    }
    setPasswordError('');
    return true;
  };
  
  


  const handleSubmit = (e: any) => {
    e.preventDefault();

    if(!isValidEmail(formData.email) || !validatePassword(formData.password)) {
      return;
    }

    if(!isValidEmail(formData.email)) {
      return;
    }

    const formElements = e.currentTarget.elements;
    if (formElements.password.value !== formElements.confirmPassword.value) {
      handleOpenDialog('Passwords do not match');
      return;
    }

    const data = {
      name: formElements.name.value,
      organization: formElements.organization.value,
      industry: formElements.industry.value,
      email: formElements.email.value,
      password: formElements.password.value
    };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, data)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem('ALtoken', token);
        console.log(res);
        // Delay the redirection to give the user some time to read the dialog
        setTimeout(() => {
          navigate('/dashboard');
        }, 1500);
      })
      .catch((err) => {
        handleOpenDialog(err.response.data.message);
      });
  };


  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            required
        />

        <TextField
            name="organization"
            label="Institution/Organization"
            value={formData.organization}
            onChange={handleChange}
            required
        />


        <TextField
            name="industry"
            label="Industry"
            select
            value={formData.industry}
            onChange={handleChange}
            required
        >
            {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                    {industry}
                </MenuItem>
            ))}
        </TextField>


        
        <TextField
          name="email"
          label="Email address"
          type="email"
          value={formData.email.toLowerCase()}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
          required
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={formData.password}
          onChange={handleChange}
          required
          error={!!passwordError}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        <br/>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Sign Up
      </LoadingButton>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Notification"}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {dialogMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>

    </form>
  );
}

export default SignUpForm;
