import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Button, Container, CircularProgress } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from 'react-router-dom';
import axiosWithAuth from '../../auth/login/axiosWithAuth';

const CreateChatbotPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleCreateChatbot = async () => {
    setLoading(true);

    try {
        // Replace this with your actual server request
        const response = await axiosWithAuth().get(`${[process.env.REACT_APP_BACKEND_URL]}/createChatbot`);

        if (response.data.isChatbotCreated) {
            window.location.reload();
        }

        console.log('Response received from the server.');

    } catch (error) {
        console.error('Error during the server request:', error);
    } finally {
        setLoading(false);
    }
};


  return (
    <Container maxWidth="md" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Ready to design your support agent?
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Click the button below to get started. Make sure you have added to your knowledge base!
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        sx={{ mt: 3 }}
        onClick={handleCreateChatbot}
        disabled={loading}
        startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
      >
        Create Agent
      </Button>
    </Container>
  );
}

export default CreateChatbotPage;