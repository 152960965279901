import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from 'react-router-dom';

const ComingSoonCard: React.FC = () => {
  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', // Centers content vertically
            alignItems: 'center',     // Centers content horizontally
            height: '100%'
          }}
        >
          <AutoFixHighIcon color="primary" sx={{ fontSize: '3rem', mb: 2 }} />
          <Typography variant="h5" align="center" gutterBottom>
            Knowledge Sources
          </Typography>
          <Typography variant="subtitle1" align="center">
            Additional knowledge sources from <Link to="/dashboard/integrations" style={{ textDecoration: 'underline', color: 'blue' }}>integrations</Link> are coming soon!
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ComingSoonCard;
