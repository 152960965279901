import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Box, Stack, Card, Typography, CardHeader, CircularProgress, CardContent, TextField, Button, FormHelperText, Alert } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore, TextSnippet as FileIcon, Folder as FolderIcon } from '@mui/icons-material';


type TreeStructure = {
  [key: string]: TreeStructure;
}

type ResponseData = {
    isWebScraped: boolean;
    isWebScraping: boolean;
    message: string;
    cleanedTree: TreeStructure;
    directoryName: string;
    status: number;
    statusText: string;
    pageCount: number;
    orgPlan: string;
    plan: string;
  };
  

const ConversationsCard: React.FC = () => {
  const [openKeys, setOpenKeys] = useState<Record<string, boolean>>({});
  const [websitePages, setWebsitePages] = useState<TreeStructure>({});
  const [directoryName, setDirectoryName] = useState<string>("");
  const [isScraped, setIsScraped] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isScraping, setIsScraping] = useState<boolean>(false);
  const [url, setURL] = useState<string>("");
  const [pageCountError, setPageCountError] = useState<boolean>(false);
  const [pageCountLimit, setPageCountLimit] = useState<number>(0);
  const [plan, setPlan] = useState<string>("");
  const [planForScraper, setPlanForScraper] = useState<string>("");
  const [isClicked, setIsClicked] = useState(false);

  const updateTreeKeys = (tree: TreeStructure): TreeStructure => {
    return Object.entries(tree).reduce((acc, [key, value]) => {
      const updatedKey = key === "" ? "index" : key;
      acc[updatedKey] = updateTreeKeys(value);
      return acc;
    }, {} as TreeStructure);
  };

  useEffect(() => {
    async function getWebsitePages() {
        try {
            const res: AxiosResponse<ResponseData> = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/getWebsitePages`);

            // Check if page count is greater than 250
            if (res.data.pageCount >= 100 && res.data.orgPlan === "free") {
              setPageCountLimit(100);
              setPageCountError(true);
              
            } else if (res.data.pageCount >= 100 && res.data.orgPlan === "starter") {
              setPageCountLimit(100);
              setPageCountError(true);
              
            } else if (res.data.pageCount >= 250 && res.data.orgPlan === "standard") {
              setPageCountLimit(250);
              setPageCountError(true);
              
            }
            setPlan(res.data.orgPlan);

            const updatedTree = updateTreeKeys(res.data.cleanedTree);
            const dirName = res.data.directoryName;
            
            // if dirName has an underscore in the name, replace the underscore with a period
            setDirectoryName(dirName);
            if (dirName.includes("_")) {
                const updatedDirName = dirName.replace("_", ".");
                const link = updatedDirName.startsWith('http://') || updatedDirName.startsWith('https://')
                    ? updatedDirName
                    : `http://${updatedDirName}`;
                setURL(link);
                setDirectoryName(updatedDirName);
            }
            setWebsitePages(updatedTree);
            setLoading(false);
        } catch (error: Error | any) {
            // Check if the error response has the specific 404 message
          if (error.response && error.response.status === 404 && error.response.data.message === "Collection not found") {
            console.log("Collection not found");
          } else {
            console.error("Error fetching website pages:", error);
          }
        }
    }

    async function isWebScraped() {
      // First check if website has been scraped
      try {
        const res: AxiosResponse<ResponseData> = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/isWebScraped`);
        setIsScraped(res.data.isWebScraped);
        setPlanForScraper(res.data.plan);
        if (res.data.isWebScraped) {
          getWebsitePages();
        }
      } catch (error: Error | any) {
        console.error("Error checking if web scraping:", error);
      }
      if (!isScraped) {
        try {
          const res: AxiosResponse<ResponseData> = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/isWebScraping`);
          setIsScraping(res.data.isWebScraping);
          setLoading(false);
        } catch (error: Error | any) {
          console.error("Error checking if web scraping:", error);
        }
      }
    }
    
    isWebScraped();

  }, []);
  

  const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-.]+\.[a-zA-Z]{2,4}(\/\S*)?$/;


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsClicked(true)
    let processedURL = url;

        
    if (!urlRegex.test(processedURL)) {
      setErrorMessage("Invalid URL format. Please ensure it matches the following format of http://example.com.");
      return;
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      processedURL = `http://${url}`;
    }

  
    // Check if the URL exists using server-side route
    try {
      const response = await axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/setWebsiteUrl`, { processedURL, planForScraper });
      if (response.status === 200) {
        // Assuming success if we've gotten this far
        // Clear the URL
        setURL("");
        setErrorMessage(""); // clear any previous error messages
        setIsScraping(true);
      }
    } catch (error: Error | any) { 
      if (error.response && error.response.status === 404) {
        setErrorMessage("The provided URL doesn't seem to exist or is not accessible.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };
  

  

  const handleClick = (key: string) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const axiosWithAuth = () => {
    const token = localStorage.getItem('ALtoken');
    return axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const renderTree = (tree: TreeStructure, depth = 0): JSX.Element[] => {
    return Object.entries(tree).map(([key, value]) => (
      <React.Fragment key={key}>
        <ListItemButton onClick={() => handleClick(key)}>
          <ListItemIcon>
            {Object.keys(value).length === 0 ? <FileIcon /> : <FolderIcon />}
          </ListItemIcon>
          <ListItemText primary={key} />
          {Object.keys(value).length !== 0 && (openKeys[key] ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        {Object.keys(value).length !== 0 && (
          <Collapse in={openKeys[key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderTree(value, depth + 1)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  if (loading) {
    // Display a loading card with a spinning indicator while loading
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      </Box>
    );
  }


  return (
    <Box sx={{ width: '100%' }}>
      <Card>
          <CardHeader title="Website Knowledge" />
          {pageCountError && (
            <Alert severity="warning" sx={{ margin: 2 }}>
              Your website's page count exceeds {pageCountLimit} pages. Some pages may be excluded from your agent's knowledge base. Please upgrade your plan, or contact us to increase your page count allowance.
            </Alert>
          )}

          {isScraped ? (
            <Stack spacing={3} sx={{ p: 3 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Here you can see which website pages are included in the knowledge base.
              </Typography>
              
              <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                          <a href={url} target="_blank" rel="noreferrer">
                              {directoryName}
                          </a>
                      </ListSubheader>
                  }
              >
                  {renderTree(websitePages)}
              </List>
          </Stack>
          ) : (
              <>
                  {isScraping ? (
                    <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                      <CircularProgress size={50} />
                      <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        Teaching our AI Support Agents
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        We are currently training our AI support agents on your website. This may take several minutes.
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        We will email you when the training is complete.
                      </Typography>
                  </CardContent>
                  ) : (
                    <Stack spacing={3} sx={{ p: 3 }}>
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        Welcome to the knowledge base! 🎉 This is where you can add information for our AI support agents to reference. Let's get started by adding your website.
                      </Typography>
                      <form onSubmit={handleSubmit}>
                        <TextField
                          label="Website URL"
                          variant="outlined"
                          value={url}
                          onChange={(e) => setURL(e.target.value)}
                          fullWidth
                          placeholder="https://example.com"
                          error={!!errorMessage}
                        />
                        <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>
                        <Box sx={{ marginTop: 2 }}>
                          <Button variant="contained" color="primary" type="submit" disabled={isClicked}>
                            Submit
                          </Button>
                        </Box>
                      </form>
                    </Stack>
                  )}
              </>
          )}
      </Card>
    </Box>
  );



};

export default ConversationsCard;
