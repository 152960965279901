import React, { useRef, useEffect, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import CreateChatbot from '../sections/@dashboard/designer/CreateChatbot';
import ChatbotDesigner from '../sections/@dashboard/designer/ChatbotDesigner';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';

const DesignerPage: React.FC = () => {
  const [isChatbotCreated, setIsChatbotCreated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [chatbotData, setChatbotData] = React.useState({});

  useEffect(() => {
    axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/isDesignerInitialized`)
      .then(res => {
        if (res.data.isChatbotCreated) {
          setIsChatbotCreated(true);
          setIsLoading(false);
          setChatbotData(res.data.chatbot);
        } else {
          setIsChatbotCreated(false);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Designer | Ask Lilac</title>
      </Helmet>
      {isLoading ? (
        <div/>
      ) : isChatbotCreated ? (
        <ChatbotDesigner message={chatbotData} />
      ) : (
        <CreateChatbot />
      )}
    </>
  );  
};

export default DesignerPage;
