// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Conversations',
    path: '/conversations/all',
    icon: <Iconify icon="ant-design:wechat-filled" />,
    children: [
      { title: "All", path: "/conversations/all" },
      { title: "Prospects", path: "/conversations/prospects" },
      { title: "Current Families", path: "/conversations/current" },
      { title: "Reply Requests", path: "/conversations/reply-requests" },
    ]
  },
  {
    title: 'Knowledge Base',
    path: '/knowledge-base',
    icon: <Iconify icon="octicon:book-16" />,
  },
  {
    title: 'Designer',
    path: '/designer',
    icon: <Iconify icon="mdi:paint-outline" />,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: <Iconify icon="mdi:chart-bar-stacked" />,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    icon: <Iconify icon="ant-design:setting-filled" />,
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: <Iconify icon="streamline:money-cash-coins-stack-accounting-billing-payment-stack-cash-coins-currency-money-finance" />,
  },
  {
    title: 'Account',
    path: '/account',
    icon: <Iconify icon="mdi:account-outline" />,
  },
];

export default navConfig;
