import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';
import { InsertDriveFile as FileIcon } from '@mui/icons-material';
import axiosWithAuth from '../../auth/login/axiosWithAuth';

interface GoogleDriveFileListProps {
}

const GoogleDriveFileList: React.FC<GoogleDriveFileListProps> = () => {
    const [files, setFiles] = useState<string[]>([]);

    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/api/drive/folderContents`)
            .then(res => setFiles(res.data))
            .catch(err => console.error(err));
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Google Drive Files
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} gutterBottom>
                        Below are the files from your shared Ask Lilac Google Drive folder that are included in the knowledge base. Current files types supported are Word, PDF, Powerpoint, Google Docs, and Google Slides.
                    </Typography>
                    <List>
                        {files.map((fileName, index) => (
                            <ListItem key={fileName}> {/* Use fileName as key */}
                                <ListItemIcon>
                                    <FileIcon />
                                </ListItemIcon>
                                <ListItemText primary={fileName} />
                                {/* If you have a link or other info, add it here */}
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Box>
    );
};

export default GoogleDriveFileList;
