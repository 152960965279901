import React, { useEffect } from 'react';
import { Grid, Typography, Card, CardContent, Button, CircularProgress, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import axiosWithAuth from '../../auth/login/axiosWithAuth';

type ActiveSubscriptionProps = {
  Plan: string;
    handleCancelSubscription: () => void;
    isCancelling: boolean;
};

export default function ActiveSubscription({ Plan, handleCancelSubscription, isCancelling }: ActiveSubscriptionProps) {

    const [selectedPlan, setSelectedPlan] = React.useState(Plan);
    const [conversations, setConversations] = React.useState(0);
    const [conversationAllocation, setConversationAllocation] = React.useState(0);
    const [webpageAllocation, setWebpageAllocation] = React.useState(0);
    const [webpages, setWebpages] = React.useState(0);
    const [amountDue, setAmountDue] = React.useState('$0.00');
    const [meteredUsage, setMeteredUsage] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);


    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/billingUsage`)
            .then(res => {
                setConversations(res.data.conversationCount);
                setWebpages(res.data.webpageCount);
            }).catch(err => {
                console.log(err);
            });
    }, []);  // This effect runs only once when the component mounts
    
    useEffect(() => {
        if (selectedPlan === 'starter') {
            setAmountDue('$249.95');
            setWebpageAllocation(100);
            setConversationAllocation(100);
            if (conversations > 100) {
                const counter = conversations - 100;
                const divide100 = counter / 100;
                const roundedAmount = Math.ceil(divide100);
                const totalMetered = roundedAmount * 62;
                setMeteredUsage(totalMetered);
            }
        } else if (selectedPlan === 'standard') {
            setAmountDue('$499.95');
            setWebpageAllocation(250);
            setConversationAllocation(300);
            if (conversations > 300) {
                const counter = conversations - 300;
                const divide100 = counter / 100;
                const roundedAmount = Math.ceil(divide100);
                const totalMetered = roundedAmount * 55;
                setMeteredUsage(totalMetered);
            }
        } else if (selectedPlan === 'Enterprise') {
            setAmountDue('$500');
            setWebpageAllocation(500);
            setConversationAllocation(250);
            if (conversations > 250) {
                const counter = conversations - 250;
                const divide100 = counter / 100;
                const roundedAmount = Math.ceil(divide100);
                const totalMetered = roundedAmount * 49;
                setMeteredUsage(totalMetered);
            }
        } else if (selectedPlan === 'Enterprise Pilot Program') {
            setAmountDue('$715');
            setWebpageAllocation(500);
            setConversationAllocation(250);
            if (conversations > 250) {
                const counter = conversations - 250;
                const divide100 = counter / 100;
                const roundedAmount = Math.ceil(divide100);
                const totalMetered = roundedAmount * 62;
                setMeteredUsage(totalMetered);
            }
        }
    }, [selectedPlan, conversations]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">Active Subscription</Typography>
                        <br />
                        <Typography variant="body2">You are currently subscribed to the <strong>{selectedPlan}</strong> plan.</Typography>
                        <br />
                        <Typography variant="body2">Your Support Agent has engaged in:</Typography>
                        <LinearProgress variant="determinate" value={(conversations / conversationAllocation) * 100} />
                        <Typography variant="caption">{conversations} out of {conversationAllocation} included conversations</Typography>
    
                        <Typography variant="body2" style={{ marginTop: '16px' }}>Your knowledge base contains:</Typography>
                        <LinearProgress variant="determinate" value={(webpages / webpageAllocation) * 100} />
                        <Typography variant="caption">{webpages} out of {webpageAllocation} webpages</Typography>
                        <br />
                        <Typography variant="body2" style={{ marginTop: '16px' }}>Your billing cycle is the 2nd of each month.</Typography>
                        <br />
                        <Typography variant="body2">Your next billing amount is {amountDue} + ${meteredUsage}.</Typography>
                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">Upgrade Plan</Typography>
                        <br />
                        <Typography variant="body2">To change your plan, please reach out to us at <a href="mailto:support@asklilac.com">support@asklilac.com</a>.</Typography>
                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" color="error">Danger Zone</Typography>
                        <br />
                        <Typography variant="body2">Click the button below to cancel your subscription. You will immediately lose access to all of your plan's features.</Typography>
                        
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenDialog}
                            disabled={isCancelling}
                            style={{
                                marginTop: '20px',
                                background: 'red',
                                width: '175px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center' // Horizontal centering
                            }}>
                            {isCancelling ? (
                                <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }} />
                            ) : (
                                "Cancel Subscription"
                            )}
                        </Button>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Cancel Subscription"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to cancel your subscription? You will immediately lose access to all of your plan's features.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    No
                                </Button>
                                <Button 
                                    onClick={() => {
                                        handleCancelSubscription(); 
                                        handleCloseDialog();
                                    }}
                                    style={{ color: 'red' }}
                                    autoFocus
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
      );
}
