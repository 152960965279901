import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Collapse,
    IconButton,
    Box,
    ButtonBase,
    Divider,
    Grid,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

interface Props {}

const InstructionsCard: React.FC<Props> = () => {
    const [expanded, setExpanded] = useState(false);
    const handleToggleExpand = () => {
        setExpanded(!expanded);
    }

    return (
        <ButtonBase onClick={handleToggleExpand} style={{ width: '100%', borderRadius: '4px' }}>
            <Card variant="outlined" style={{ width: '100%' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
                    <Box flexGrow={1} display="flex" justifyContent="center">
                        <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                            Integration Instructions
                        </Typography>
                    </Box>
                    <IconButton edge="end" onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expanded}>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Add the above script to the footer of your website. Here are some instructions for the most popular platforms:
                        </Typography>
                        <br />

                        <Grid container spacing={3} sx={{ padding: 2 }}>
                            {/* ...platforms' instructions... */}
                            {/* Shopify */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/ShopifyIcon.svg" alt="Shopify" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">Shopify: Go to Online Store {'>'} Themes {'>'} Actions {'>'} Edit Code. Add your script to the `theme.liquid` file before the closing {`</body>`} tag.</Typography>
                            </Grid>

                            {/* Squarespace */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/SquarespaceIcon.svg" alt="Squarespace" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">Squarespace: Go to Settings {'>'} Advanced {'>'} Code Injection and add your script to the Footer section.</Typography>
                            </Grid>

                            {/* Webflow */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/WebflowIcon.svg" alt="Webflow" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">Webflow: Go to Project Settings {'>'} Custom Code {'>'} Footer Code and add your script.</Typography>
                            </Grid>

                            {/* Weebly */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/WeeblyIcon.svg" alt="Weebly" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">Weebly: Go to Settings {'>'} SEO {'>'} Footer Code and add your script.</Typography>
                            </Grid>

                            {/* Wix */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/WixIcon.svg" alt="Wix" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">Wix: Go to Settings {'>'} Custom Code {'>'} Add Custom Code. Choose 'Load code on each new page' and add your script to the Footer section.</Typography>
                            </Grid>

                            {/* WordPress */}
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/assets/icons/WordpressIcon.svg" alt="WordPress" style={{ marginBottom: 10, width: 100 }} />
                                <Typography variant="body2">WordPress: Go to Appearance {'>'} Theme Editor {'>'} `footer.php` and add your script before the closing {`</body>`} tag.</Typography>
                            </Grid>
                        </Grid>

                        
                    </CardContent>
                </Collapse>
            </Card>
        </ButtonBase>
    );
}

export default InstructionsCard;
