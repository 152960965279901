import React, { useState, useEffect } from 'react';
import { useStripe, CardElement, useElements, AddressElement } from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet-async';
import { 
    CircularProgress, Stack, TextField, Typography, Container, FormControlLabel, Radio, RadioGroup, Button, Card, CardContent, Grid 
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';
import Plans from '../sections/@dashboard/billing/Plans';
import ActiveSubscription from '../sections/@dashboard/billing/ActiveSubscription';
import ContactCard from '../layouts/dashboard/ContactCard';


// create a subscription type for typescript
type Subscription = {
  status: string;
  plan: string;
    metadata: {
        plan: string;
    }
    id: string;
}
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
        iconColor: "#c4f0ff", 
        lineHeight: '24px',
      },
      invalid: {
        color: '#9e2146',
      },
    },
};
const wrapperStyles = {
    padding: '15px 20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    backgroundColor: 'white',
};
  


export default function BillingPage() {
    const stripe = useStripe();
    const elements = useElements();

    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [selectedPlan, setSelectedPlan] = useState('standard');
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isBilling, setIsBilling] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isCancelling, setIsCancelling] = useState(false);
    const [plan, setPlan] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    const [billingDetails, setBillingDetails] = useState({
        name: '',
        address: {
            line1: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
        },
    });
    const showAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };
    

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const res = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/subscription`);
                if (res.data.isSubscribed === true) {
                    
                    if (res.data.subscription) {
                        setSubscription(res.data.subscription);
                    } else {
                        setSubscription(null);
                    }
                    if (res.data.plan === 'enterprisePilot') {
                        setPlan("Enterprise Pilot Program");
                    } else {
                        setPlan(res.data.plan);
                    }
                    setIsSubscribed(true);
                    
                } else {
                    setSubscription(null);
                    setIsSubscribed(false);
                }

            } catch (error: any) {
                console.error('Error fetching subscription:', error.response.data.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscription();
    }, []);


    const handleSubscribe = async () => {
        setIsBilling(true);
        
        const cardElement = elements!.getElement(CardElement);
        
        const result = await stripe!.createPaymentMethod({
            type: 'card',
            card: cardElement!,
        });
        
        if (result.error) {
            showAlert(result.error.message || "Failed to create payment method.");
            setIsBilling(false);
            return;
        }
        
        const paymentMethod = result.paymentMethod;
    
        if (!paymentMethod) {
            showAlert("Failed to create payment method.");
            setIsBilling(false);
            return;
        }

        const customer = await axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/createCustomer`, {
            paymentMethodId: paymentMethod.id
        });
        if (customer.data.error) {
            console.error(customer.data.error.message);
            showAlert(customer.data.error.message);
            setIsBilling(false);
            return;
        }
        setCustomerId(customer.data.customer.id);
        
        
        

        // Now create the subscription using the attached payment method
        const response = await axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/createSubscription`, {
            customerId: customer.data.customer.id || '',
            paymentMethodId: customer.data.paymentMethod.id,
            plan: selectedPlan,
        });
    
        const sub = await response.data;
        setSubscription(sub);
        setIsBilling(false);
        setIsSubscribed(true);
        setPlan(selectedPlan);


    };

    const handleCancelSubscription = async () => {
        setIsCancelling(true);
        alert("Please contact support to cancel your organization's plan.");
        setIsCancelling(false);
        /*
        if (plan === 'custom') {
            alert('You cannot cancel a custom plan. Please contact support.');
            setIsCancelling(false);
        } else {
            try {
                
                const response = await axiosWithAuth().post(`${process.env.REACT_APP_BACKEND_URL}/cancelSubscription`, {
                    subscriptionId: subscription?.id,
                    customerId
                });
                setIsCancelling(false);
                setIsSubscribed(false);
                setSubscription(null);
            } catch (error: any) {
                console.error(error.response.data.message);
                showAlert(error.response.data.message);
                setIsCancelling(false);
            }
        }
        */
    }

    if (loading) {
        return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Container>
        );
    }

    
    

  return (
    <>
        <Helmet>
          <title> Billing | Ask Lilac </title>
        </Helmet>
        <Container maxWidth="md">
              <Typography variant="h4" gutterBottom>
                  Billing
              </Typography>
              <Snackbar
                  open={alertOpen}
                  autoHideDuration={6000}
                  onClose={() => setAlertOpen(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                  <Alert
                      onClose={() => setAlertOpen(false)}
                      severity="error"
                      variant="filled"
                  >
                      {alertMessage}
                  </Alert>
              </Snackbar>
              <Stack direction="column" spacing={2}>
                  {/* Display message if isSubscribed = false */}
                  <Typography variant="h6" gutterBottom>
                      {isSubscribed ? '' : 'Select a Plan Type'}
                  </Typography>
                  {isSubscribed ? (
                      <>
                          <ActiveSubscription Plan={plan} handleCancelSubscription={handleCancelSubscription} isCancelling={isCancelling} />
                            <div style={{ padding: '20px', width: '105%'}}>
                                <ContactCard />
                            </div>
                      </>
                  ) : (
                      <>
                          <Card>
                              <Plans selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
                          </Card>

                          <Card>

                              <CardContent>

                                  <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                      Enter your billing details:
                                  </Typography>



                                  <Card>
                                      <div style={wrapperStyles}>
                                          <CardElement options={CARD_ELEMENT_OPTIONS} />
                                      </div>
                                  </Card>


                                  <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSubscribe}
                                      disabled={isBilling}
                                      style={{
                                          marginTop: '20px',
                                          position: 'relative',
                                          width: '120px',
                                          height: '40px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center' // Horizontal centering
                                      }}>
                                      {isBilling ? (
                                          <CircularProgress size={24} />
                                      ) : (
                                          "Subscribe"
                                      )}
                                  </Button>

                              </CardContent>
                          </Card>
                            <div style={{ padding: '20px', width: '100%'}}>
                                <ContactCard />
                            </div>
                      </>
                  )}
                    


              </Stack>



          </Container></>
  );
}
