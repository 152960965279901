import React, { useState } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// @ts-ignore
import Iconify from '../../../components/iconify';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const showAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'remember' ? checked : value
    }));
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData.password === '') {
      showAlert('Please enter your password!', 'warning');
      return;
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, formData)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem('ALtoken', token);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        showAlert('User login failed');
      });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
      setOpenDialog(false);
  };


  return (
    <>
    <Snackbar
      open={alertOpen}
      autoHideDuration={6000}
      onClose={() => setAlertOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={() => setAlertOpen(false)}
        severity="error"
        variant="filled"
      >
        {alertMessage}
      </Alert>
    </Snackbar><form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={formData.email.toLowerCase()}
            onChange={handleChange} />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }} />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/*
    <Checkbox
      name="remember"
      checked={formData.remember}
      onChange={handleChange}
    />
    */}
          <Link href="#" variant="subtitle2" underline="hover" onClick={handleOpenDialog}>
            Forgot password?
          </Link>

        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{"Forgot Password"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please contact us to reset your password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </form></>
  );
}
