import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Typography, Box } from '@mui/material';

// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 351;
const CHART_HEIGHT_SMALLER = 351;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme, hasNonZeroData }) => ({
  height: hasNonZeroData ? CHART_HEIGHT : CHART_HEIGHT_SMALLER, // Apply different heights
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: hasNonZeroData ? CHART_HEIGHT : '100%' }, // If no data, let SVG take full height of its container
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: hasNonZeroData ? `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important` : 'auto', // When there is no data, the position is auto
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppCurrentVisits({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();

  const hasNonZeroData = chartData.some((item) => item.value !== 0);

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <StyledChartWrapper dir="ltr" hasNonZeroData={hasNonZeroData}>
        {hasNonZeroData ? (
          <ReactApexChart type="pie" series={chartData.map((i) => i.value)} options={chartOptions} height={280} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              height: CHART_HEIGHT,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle1">No data available</Typography>
          </Box>
        )}
      </StyledChartWrapper>
    </Card>
  );
}
