// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress
} from '@mui/material';
// components
// @ts-ignore
import Label from '../../../components/label';
// @ts-ignore
import Iconify from '../../../components/iconify';
// @ts-ignore
import Scrollbar from '../../../components/scrollbar';
// sections
// @ts-ignore
import { UserListHead, UserListToolbar } from '../user';
// mock
// @ts-ignore
import axiosWithAuth from '../../auth/login/axiosWithAuth';
import ProspectDetail from './ProspectDetail';

// ----------------------------------------------------------------------

const initialTableHead = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'Date') {
    const dateA = new Date(a.Message[0].createdAt);
    const dateB = new Date(b.Message[0].createdAt);
    if (dateB < dateA) return -1;
    if (dateB > dateA) return 1;
    return 0;
  }
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  
  if (!Array.isArray(array)) {
    return [];
  }
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_message) => 
      _message.name && 
      _message.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}



export default function ProspectsView() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('Date');


  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [messages, setMessages] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [tableHead, setTableHead] = useState(initialTableHead);
  const [hasEntryYear, setHasEntryYear] = useState(false);
  const [hasCurrentSchool, setHasCurrentSchool] = useState(false);



  useEffect(() => {
    // Fetch messages from Prisma
    async function fetchData() {
      await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/getProspects`)
        .then((res) => {

          // Check if any prospect has a non-null and non-undefined entryYear and currentSchool
          const entryYearExists = res.data.some(prospect => prospect.entryYear != null);
          const currentSchoolExists = res.data.some(prospect => prospect.currentSchool != null);

          setHasEntryYear(entryYearExists);
          setHasCurrentSchool(currentSchoolExists);

          const newTableHead = [...initialTableHead];
          if (entryYearExists) {
            newTableHead.push({ id: 'entryYear', label: 'Entry Year', alignRight: false });
          }
          if (currentSchoolExists) {
            newTableHead.push({ id: 'currentSchool', label: 'Current School', alignRight: false });
          }

          // Then add 'location'
          newTableHead.push({ id: 'location', label: 'Location', alignRight: false });
          // Add 'Date' column here
          newTableHead.push({ id: 'Date', label: 'Date', alignRight: false });

          setTableHead(newTableHead);


          // Filter out prospects with no messages
          const filteredProspects = res.data.filter(prospect => prospect.Message && prospect.Message.length > 0);
          
          setMessages(Array.isArray(filteredProspects) ? filteredProspects : []);
          setFilteredUsers(applySortFilter(filteredProspects, getComparator(order, orderBy), filterName));
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetchData();
    
  }, [filterName, order, orderBy]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messages.length) : 0;


  const isNotFound = !filteredUsers.length && !!filterName;

  const handleFilterClick = (filterValue) => {
    const filteredU = messages.filter(user => user.name.includes(filterValue));
    setFilteredUsers(filteredU);  // assuming you want to update the filteredUsers state
  };

  if (isLoading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Prospects | Ask Lilac </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Prospects
          </Typography>
          {/*
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
          */}
        </Stack>
        
        {selectedProspect ? (
          <ProspectDetail prospect={selectedProspect} onBack={() => setSelectedProspect(null)} />

        ) : (
          <Card>
            <UserListToolbar 
              numSelected={selected.length} 
              filterName={filterName} 
              onFilterName={handleFilterByName}
              onFilterClick={handleFilterClick}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                        {tableHead.map((head) => (
                            <TableCell key={head.id} align={head.alignRight ? 'right' : 'left'}>
                                {head.label}
                            </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  {/* ... Table Head ... */}
                  <TableBody>
                    {Array.isArray(filteredUsers) && filteredUsers.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={tableHead.length} align="center">
                          No conversations yet
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((msg) => (
                        <TableRow hover key={msg.id} tabIndex={-1} onClick={() => setSelectedProspect(msg)}>
                          <TableCell component="th" scope="row">{msg.name}</TableCell>
                          <TableCell align="left">{msg.email}</TableCell>
                          {hasEntryYear && <TableCell align="left">{msg.entryYear}</TableCell>}
                          {hasCurrentSchool && <TableCell align="left">{msg.currentSchool}</TableCell>}
                          <TableCell align="left">{msg.location ? `${msg.location} ${msg.locationEmoji || ''}` : 'Unknown'}</TableCell>
                          <TableCell align="left">{new Date(msg.Message[0].createdAt).toLocaleString()}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Card>

        )}
        
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
