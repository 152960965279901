// @mui
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

ConversationsCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  isEmpty: PropTypes.bool,
};

export default function ConversationsCard({ showViewAllButton, title, subheader, list, onViewAll, isEmpty, ...other }) {
  
  const [displayedMessagesCount, setDisplayedMessagesCount] = React.useState(5);

  function handleViewAll() {
    setDisplayedMessagesCount(prevCount => prevCount + 5);
  }
  
  
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {isEmpty ? (
            <Typography align="center" sx={{ my: 2 }}>
              Our Support Agent has been eating its virtual veggies – no questions left on its plate!
            </Typography>
          ) : (
            list.map((news) => <NewsItem key={news.id} news={news} />)
          )}
        </Stack>
      </Scrollbar>

      <Divider />

      {showViewAllButton && !isEmpty && (
        <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button 
            size="small" 
            color="inherit" 
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
            onClick={onViewAll}
          >
            View more
          </Button>
        </Box>
      )}
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>
        {/*
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>
        */}

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}
