import ReactDOM from 'react-dom/client';
import React from 'react';

import App from './App';
import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
const rootElement = document.getElementById('root') as HTMLElement;

if (!rootElement) {
  throw new Error("Couldn't find the root element.");
}

const root = ReactDOM.createRoot(rootElement);

root.render(<App />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
