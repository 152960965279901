// ContactCard.tsx

import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Collapse,
    IconButton,
    Box,
    ButtonBase,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

interface Props {}

const ContactCard: React.FC<Props> = () => {
    const [expanded, setExpanded] = useState(false);
    const handleToggleExpand = () => {
        setExpanded(!expanded);
    }

    return (
        <ButtonBase onClick={handleToggleExpand} style={{ width: '100%', borderRadius: '4px' }}>
            <Card variant="outlined" style={{ width: '100%' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
                    <Box flexGrow={1} display="flex" justifyContent="center">
                        <Typography variant="h6" component="div" style={{fontWeight: 'normal'}}>
                            Require Assistance?
                        </Typography>
                    </Box>
                    <IconButton edge="end" onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expanded}>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Feel free to reach out to us if you have any questions or feature suggestions. We would love to hear from you!
                        </Typography>
                        <br />
                        <Typography variant="body2" color="textSecondary" component="p">
                            <a href="mailto:support@asklilac.com">support@asklilac.com</a>
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </ButtonBase>
    );
}

export default ContactCard;
