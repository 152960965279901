import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Box, CircularProgress } from '@mui/material';
import axiosWithAuth from '../../auth/login/axiosWithAuth';


// Define consistent image styling
const imageStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'contain'
};

const GoogleDriveIcon = () => (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center"
      sx={{ width: '100%', mt: 2 }}
    >
      <Box 
        component="img" 
        src={`${process.env.PUBLIC_URL}/assets/integrations/drive.png`} 
        alt='Google Drive' 
        sx={imageStyle} 
      />
    </Box>
);

const GoogleDriveIntegration = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [orgID, setOrgID] = useState('');
    const [isLoading, setIsLoading] = useState(true); // New loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/organizationID`);
                setOrgID(res.data.organizationID);
                await checkAuthenticationStatus();
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false); // Set loading to false after fetching data
            }
        };

        fetchData();
    }, []);

    const checkAuthenticationStatus = async () => {
        try {
            const response = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/api/checkGoogleAuthentication`);
            setIsAuthenticated(response.data.isGoogleAuthenticated);
        } catch (error) {
            console.error('Error checking authentication status', error);
        }
    };

    const handleAuthClick = async () => {
        if (isAuthenticated) {
            try {
                await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/google/signout`);
                setIsAuthenticated(false);
            } catch (error) {
                console.error('Error signing out:', error);
            }
        } else {
            window.location.href = `${process.env.REACT_APP_BACKEND_URL}/google/auth?orgID=${orgID}`;
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Card sx={{ textAlign: 'center', backgroundColor: '#f5f5f5' }}>
            <CardContent>
                <GoogleDriveIcon />
                <Typography variant="h5" mt={2}>
                    Google Drive
                </Typography>
                <Typography variant="body1" mt={1}>
                    {isAuthenticated ? 'You are currently signed in. You may add supported files into the Ask Lilac folder in your Google Drive. These files will be included in the knowledge base and can also be seen on the Knowledge Base page. The following file formats are currently supported: Word, PDF, Powerpoint, Google Docs, and Google Slides.' : `By signing in, a shareable folder called "Ask Lilac" will be created in your Google Drive account. Any supported files that are added this folder will be included in your AI knowledge base. The following file formats are currently supported: Word, PDF, Powerpoint, Google Docs, and Google Slides.`}
                </Typography>
                <Button variant="contained" color="primary" onClick={handleAuthClick} sx={{ mt: 2 }}>
                    {isAuthenticated ? 'Sign out' : 'Authenticate with Google'}
                </Button>
            </CardContent>
        </Card>
    );
};

export default GoogleDriveIntegration;
